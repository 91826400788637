import { ACCESS_TOKEN } from "@/constants/access-token";
import { ACCESS_REFRESH_TOKEN } from "@/constants/access-refresh-token";

export const getAuthorization = (): string => {
    const token = localStorage.getItem(ACCESS_TOKEN);

    return token || "";
};

export const setAuthorization = (token: string): void => {
    localStorage.setItem(ACCESS_TOKEN, token);
};

export const setRefrershToken = (refToken: string): void => {
    localStorage.setItem(ACCESS_REFRESH_TOKEN, refToken);
};

export const getRefreshToken = (): string => {
    const refreshToken = localStorage.getItem(ACCESS_REFRESH_TOKEN);

    return refreshToken || "";
};

// import { getRefreshToken, setAuthorization, getAuthorization } from '@/utils/storageFactory'
