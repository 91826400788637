import type { IAxiosResponse } from "@/types/axios-response";
import type { IUserRefreshResponse } from "@/api/types/auth";

import Axios, { AxiosError } from "axios";

import { ApiConfig } from "./config";

import { apiBaseUrl } from "@/constants/api-base-urls";
import Queue from "@/api/queue";
import { getRefreshToken, setAuthorization, getAuthorization } from "@/utils/storageFactory";
import { ACCESS_REFRESH_TOKEN } from "@/constants/access-refresh-token";
import { ACCESS_TOKEN } from "@/constants/access-token";

let isRefreshing = false;
const FailRequestQueue = new Queue();

const processQueue = (error: AxiosError | null, token: string | null) => {
    const QueueList = FailRequestQueue.show();

    QueueList.forEach((promise: any) => {
        if (error) {
            promise.reject(error);
        } else {
            promise.resolve(token);
        }
    });
    FailRequestQueue.empty();
};

const AxiosInstance = Axios.create(ApiConfig);

// Add a request interceptor
AxiosInstance.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        const token = getAuthorization();

        if (token) config.headers.Authorization = `Bearer ${token}`;

        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
AxiosInstance.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    async function (error) {
        const refreshToken = getRefreshToken();

        if (!error.response || !refreshToken) return Promise.reject(error);
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest.Retry) {
            if (isRefreshing) {
                return new Promise((resolve, reject) => {
                    FailRequestQueue.enqueue({ resolve, reject });
                })
                    .then((token) => {
                        originalRequest.headers.Authorization = `Bearer ${token}`;

                        return Axios(originalRequest);
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            }

            isRefreshing = true;
            originalRequest.Retry = true;

            return new Promise(async (resolve, reject) => {
                try {
                    const refreshTokenResult = await Axios.get<IAxiosResponse<IUserRefreshResponse>>(
                        `${apiBaseUrl}auth/admin/refresh`,
                        {
                            headers: {
                                Authorization: "Bearer " + getRefreshToken(),
                            },
                        }
                    );

                    if (refreshTokenResult.status !== 200) reject(error);

                    const accessToken = refreshTokenResult.data.data.token;

                    setAuthorization(accessToken);

                    if (originalRequest) {
                        isRefreshing = false;
                        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
                        resolve(Axios(originalRequest));
                    }

                    if (FailRequestQueue.length > 0) processQueue(null, accessToken);
                } catch (err) {
                    localStorage.removeItem(ACCESS_TOKEN);
                    localStorage.removeItem(ACCESS_REFRESH_TOKEN);
                    reject(err);
                    FailRequestQueue.empty();
                    // ReDirect
                }
            });
        }

        return Promise.reject(error);
    }
);

export default AxiosInstance;
