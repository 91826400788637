import { create } from "zustand";

import { IAuthenticatedUser } from "@/api/types/auth";

export interface IAuthStore {
    user: IAuthenticatedUser | null;
    userPermissions?: string[];
    actions: {
        setUser: (value: IAuthenticatedUser) => void,
        clearUser: () => void,
        setUserPermissions: (values: string[]) => void,
        clearUserPermissions: () => void,
    };
}

export const useAuthStore = create<IAuthStore>((set) => ({
    user: null,
    userPermissions: [],
    actions: {
        setUser: (value) => set({ user: value }),
        clearUser: () => set({ user: undefined }),
        setUserPermissions: (values) => set({ userPermissions: values }),
        clearUserPermissions: () => set({ userPermissions: [] }),
    },
}));

export const useAuthActions = () => useAuthStore((store) => store.actions);
export const useUser = () => useAuthStore((store) => store.user);
export const useUserPermissions = () => useAuthStore((store) => store.userPermissions);
