import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormControl, Link, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Helmet } from "react-helmet-async";

import { loginValidationSchema } from "./validation";
import { ILoginFormData } from "./form-type";
import { loginSchema } from "./schema";

import ROUTE_CONSTANTS from "@/constants/route-constants";
import FormGenerator from "@/components/kit/FormGenerator";
import { generateDefaultValueFromFormSchema } from "@/utils/generate-default-value-from-form-schema";
import { useAuth } from "@/hooks/useAuth";

const Login: React.FC = () => {
    const { login } = useAuth();

    const [loading, setLoading] = useState<boolean>(false);

    const {
        control,
        formState: { errors, isValid },
        handleSubmit,
    } = useForm<ILoginFormData>({
        defaultValues: generateDefaultValueFromFormSchema(loginSchema),
        mode: "all",
        resolver: yupResolver(loginValidationSchema),
    });

    const onSubmit = async (data: ILoginFormData) => {
        setLoading(true);
        await login(data);
        setLoading(false);
    };

    const footer = (
        <>
            <FormControl fullWidth margin="normal" variant="standard">
                <LoadingButton disabled={!isValid || loading} loading={loading} type="submit" variant="contained">
                    Sign In
                </LoadingButton>
            </FormControl>
            <FormControl margin="normal" variant="standard">
                <Stack direction="row" justifyContent="flex-end">
                    <Link href={ROUTE_CONSTANTS.AUTH.FORGET_PASSWORD.ABSOLUTE}>Forgot your password?</Link>
                </Stack>
            </FormControl>
        </>
    );

    return (
        <>
            <Helmet>
                <title>Tahseen | Login</title>
            </Helmet>
            <h1>Welcome back!</h1>
            <p>Login to access admin panel</p>
            <FormGenerator
                control={control}
                errors={errors}
                footer={footer}
                schema={loginSchema}
                onSubmit={handleSubmit(onSubmit)}
            />
        </>
    );
};

export default Login;
