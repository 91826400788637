import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useAuth } from "@/hooks/useAuth";
import InitLoading from "@/components/app/Loading/InitLoading";
import { useUser } from "@/context/authContext";
import { useGeneralLoading } from "@/context/global-context";
import { getAuthorization } from "@/utils/storageFactory";
import { IFunctionalComponentProps } from "@/types/functional-component";

const GeneralLayoutWrapper: React.FC<IFunctionalComponentProps> = ({ children }) => {
    const user = useUser();
    const location = useLocation();
    const { getProfile } = useAuth();
    const loading = useGeneralLoading();

    useEffect(() => {
        if (!user && !location.pathname.includes("auth")) {
            const jwt = getAuthorization();

            if (jwt) getProfile();
        }
    }, [user, getProfile, location]);

    return (
        <>
            {loading && <InitLoading height="100vh" />}
            {children}
        </>
    );
};

export default GeneralLayoutWrapper;
