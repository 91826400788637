import { Outlet } from "react-router-dom";

import { ContentWrapperStyled, MainContentWrapperStyled, MainWrapperStyled } from "./styled";

import GeneralLayoutWrapper from "@/layouts/GeneralLayoutWrapper/index";
import ModalCreator from "@/components/app/Modal/ModalCreator";
import Sidebar from "@/layouts/parts/Sidebar";
import Header from "@/layouts/parts/Header";
import { useMenuView } from "@/context/menu";
import { MENU_VIEW } from "@/enums/menu";

const DashboardLayoutContent: React.FC = () => {
    const menuView = useMenuView();

    return (
        <MainWrapperStyled className={menuView === MENU_VIEW.MINIMIZE ? "minimizeMenu" : ""}>
            <Sidebar />
            <MainContentWrapperStyled>
                <Header />
                <ContentWrapperStyled>
                    <Outlet />
                </ContentWrapperStyled>
                <ModalCreator />
            </MainContentWrapperStyled>
        </MainWrapperStyled>
    );
};

const DashboardLayout: React.FC = () => {
    return (
        <GeneralLayoutWrapper>
            <DashboardLayoutContent />
        </GeneralLayoutWrapper>
    );
};

export default DashboardLayout;
