import { IconButton, Typography, Box } from "@mui/material";
import { DeleteOutlineOutlined } from "@mui/icons-material";

import { ShowPublishersStyled } from "../style";

import { IPublisher } from "@/api/types/publisher";
import { useDeletePublisher } from "@/api/hooks/publisher";

interface IShowPublishersProps {
    data: IPublisher[];
    onDelete: () => void;
}

function PublisherItem({ publisher, onDelete }: { publisher: IPublisher, onDelete: () => void }) {
    const { id, title } = publisher;

    const { mutate } = useDeletePublisher();

    const handleDelete = () => mutate({ id: Number(id), lang: "en" }, { onSuccess: onDelete });

    return (
        <Box className="publisher-item">
            <Typography>{title}</Typography>
            <IconButton onClick={handleDelete}>
                <DeleteOutlineOutlined />
            </IconButton>
        </Box>
    );
}

export default function ShowPublishers({ data, onDelete }: IShowPublishersProps) {
    return (
        <ShowPublishersStyled>
            <Typography className="title" component="h2">
                Publishers List
            </Typography>
            {data.length === 0 && <Typography className="no-items-to-show"> There is No publisher to show </Typography>}
            <Box className="publisher-list">
                {data.map((publisher: IPublisher) => {
                    const { id } = publisher;

                    return <PublisherItem key={id} publisher={publisher} onDelete={onDelete} />;
                })}
            </Box>
        </ShowPublishersStyled>
    );
}
