import { PaletteMode } from "@mui/material";
import { create } from "zustand";

interface ThemeStore {
    theme: PaletteMode;
    setTheme: (theme: PaletteMode) => void;
}
const prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";

const initialState = localStorage.getItem("theme") || prefersDarkMode;

export const useThemeStore = create<ThemeStore>((set) => ({
    theme: initialState as PaletteMode,
    setTheme: (theme) =>
        set(() => {
            localStorage.setItem("theme", theme);

            return { theme };
        }),
}));
