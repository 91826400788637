import React from "react";
import { Outlet } from "react-router-dom";

import { AuthFormStyled, AuthImageStyled, AuthLogoStyled, AuthNizekLogoStyled } from "./styled";

import LogoWhite from "@/components/app/LogoWhite";
import LogoBlue from "@/components/app/LogoBlue";
import { useThemeStore } from "@/context/theme";
import NizekLogo from "@/assets/svg/nizek-logo";

const AuthLayoutContent: React.FC = () => {
    const { theme } = useThemeStore();

    return (
        <>
            <AuthImageStyled>
                <img
                    alt="login"
                    src="https://images.unsplash.com/photo-1615961766467-198457a6c72f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                />
            </AuthImageStyled>
            <AuthFormStyled>
                <AuthLogoStyled>{theme === "dark" ? <LogoWhite /> : <LogoBlue />}</AuthLogoStyled>
                <Outlet />
                <AuthNizekLogoStyled>
                    <span>With ❤️ By</span>
                    <a href="https://nizek.com" rel="noreferrer" target="_blank">
                        <NizekLogo height={12} />
                    </a>
                </AuthNizekLogoStyled>
            </AuthFormStyled>
        </>
    );
};

const AuthLayout: React.FC = () => {
    return <AuthLayoutContent />;
};

export default AuthLayout;
