import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Link, useLocation, useParams } from "react-router-dom";
import { Button, Typography } from "@mui/material";

import CreateSeries from "./steps/createSeries";
import CreateSeason from "./steps/createSeason";
import CreateEpisodes from "./steps/createEpisode";
import PageStyle from "./style";

import ROUTE_CONSTANTS from "@/constants/route-constants";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            aria-labelledby={`simple-tab-${index}`}
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            role="tabpanel"
            style={{ height: "100%" }}
            {...other}
        >
            {value === index && <Box className="tab-panel">{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function AddSerial() {
    const { id } = useParams();
    const { hash } = useLocation();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => setValue(newValue);

    React.useEffect(() => {
        if (hash.includes("1")) setValue(1);
    }, [hash]);

    return (
        <PageStyle className="wrapper" sx={{ width: "100%", height: "100%" }}>
            <Box className="page-header">
                <Typography className="page-title">{!id ? "Create" : "Edit"} Series</Typography>
                <Button component={Link} to={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.ROOT.ABSOLUTE}>
                    Back To Content List
                </Button>
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs aria-label="basic tabs example" value={value} onChange={handleChange}>
                    <Tab label={`${!id ? "Create" : "Update"} Series`} {...a11yProps(0)} />
                    <Tab disabled={!id} label={`${!id ? "Add" : "edit"} Seasons`} {...a11yProps(1)} />
                    <Tab disabled={!id} label={`${!id ? "Add" : "edit"} Episodes`} {...a11yProps(1)} />
                </Tabs>
            </Box>
            <CustomTabPanel index={0} value={value}>
                <CreateSeries nextStep={() => setValue(1)} />
            </CustomTabPanel>
            <CustomTabPanel index={1} value={value}>
                <CreateSeason />
            </CustomTabPanel>
            <CustomTabPanel index={2} value={value}>
                <CreateEpisodes />
            </CustomTabPanel>
        </PageStyle>
    );
}
