import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useSnackbar } from "notistack";

import AddSessionsForm from "../../../forms/addSeasonForm";
import { ICreateSeasonForm } from "../../../forms/addSeasonForm/type";

import { useCreateContent, useUpdateContent } from "@/api/hooks/useContents";
import { CONTENT_TYPE } from "@/enums/content-type";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { PERMISSIONS } from "@/enums/permissions";
import { IContentUpdatePayload } from "@/api/types/content";

interface IEpisodeModal {
    open: boolean;
    refetch?: () => void;
    onClose: () => void;
    onSuccess?: () => void;
    onSettled?: () => void;
    sessionId?: number | null;
    parentId: number;
    maxNumber?: number;
}

export default function FormModalOfSessions({
    open,
    onClose,
    onSuccess,
    onSettled,
    sessionId,
    parentId,
    maxNumber = 0,
}: IEpisodeModal) {
    const { mutate: updateSerial } = useUpdateContent();
    const { mutate: createSerial } = useCreateContent();
    const { enqueueSnackbar } = useSnackbar();

    const { checkPermissionAccess } = useCheckPermissionAccess();
    const createAccess = checkPermissionAccess(PERMISSIONS.CONTENT_CREATE);

    const handleAddSerial = (formData: ICreateSeasonForm) => {
        if (!createAccess) {
            enqueueSnackbar("You don't have access for creating a new Session");

            return;
        }

        const body: IContentUpdatePayload = {
            ml_description: {
                en: formData.en_description,
                ar: formData.ar_description,
            },
            ml_name: {
                en: formData.en_title,
                ar: formData.ar_title,
            },
            mainVideo: null,
            thumbnailLandscapeId: null,
            thumbnailPortraitId: null,
            parentId: Number(parentId),
            season: formData.autoId && maxNumber ? Number(maxNumber + 1) : formData.season,
            slug: formData.en_title.split(" ").join("_").toLowerCase(),
            typeId: CONTENT_TYPE.SESSION,
        };

        if (sessionId) {
            updateSerial(
                { id: sessionId, body },
                {
                    onSuccess: () => onSuccess && onSuccess(),
                    onSettled: () => onSettled && onSettled(),
                }
            );
        } else {
            createSerial(body, {
                onSuccess: () => {
                    if (onSuccess) onSuccess();
                    if (onClose) onClose();
                },
            });
        }
    };

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
            <DialogTitle> Season Form </DialogTitle>
            <DialogContent>
                <AddSessionsForm id={Number(sessionId)} onSubmit={handleAddSerial} />
            </DialogContent>
        </Dialog>
    );
}
