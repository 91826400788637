import {
    IPublisherGETResponse,
    IPublisherListReqBody,
    IPublisherUpdateResponse,
    IPublisherDeleteResponse,
    IPublisherCreateResponse,
    IPublisherListResponse,
} from "@/api/types/publisher";
import AxiosInstance from "@/api";
import { API_ROUTES } from "@/constants/api-routes";
import { serializeObject } from "@/utils/serializer";

export const GetPublisherById = async (id: number, lang: string) => {
    const response = await AxiosInstance.get<IPublisherGETResponse>(API_ROUTES.PUBLISHER.GET_BY_ID(id), {
        headers: { LANG: lang },
    });

    return response.data;
};

export const GetPublishersList = async (lang: string, body: IPublisherListReqBody) => {
    const response = await AxiosInstance.get<IPublisherListResponse>(
        `${API_ROUTES.PUBLISHER.LIST}?${serializeObject(body)}`,
        {
            headers: { LANG: lang },
        }
    );

    return response.data;
};

export const GetInfinitePublisherList = async (skip: number) => {
    const response = await AxiosInstance.get<IPublisherListResponse>(`${API_ROUTES.PUBLISHER.LIST}?skip=${skip}&take=10`, {
        headers: { LANG: "en" },
    });

    return response.data;
};

export const UpdatePublisherById = async (id: number, lang: string, body: { title: string }) => {
    const response = await AxiosInstance.put<IPublisherUpdateResponse>(API_ROUTES.PUBLISHER.UPDATE(id), body, {
        headers: { LANG: lang },
    });

    return response.data;
};

export const DeletePublisherById = async (id: number, lang: string) => {
    const response = await AxiosInstance.delete<IPublisherDeleteResponse>(API_ROUTES.PUBLISHER.DELETE(id), {
        headers: { LANG: lang },
    });

    return response.data;
};

export const CreatePublisherById = async (lang: string, body: { title: string }) => {
    const response = await AxiosInstance.post<IPublisherCreateResponse>(API_ROUTES.PUBLISHER.ADD, body, {
        headers: { LANG: lang },
    });

    return response.data;
};
