import { ControllerRenderProps, FieldError, FieldValues } from "react-hook-form";
import { FormControl, FormHelperText, TextField } from "@mui/material";

import { TextFieldSchema } from "@/types/form-generator-schema-type";
import RtlProvider from "@/Providers/RtlProvider";

interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    label?: string;
    placeholder: string;
    className?: string;
    errorMessage: FieldError;
    props?: TextFieldSchema["props"];
}
const FormTextarea = ({ field, label, placeholder, className, errorMessage, props = {} }: IProps) => {
    return (
        <FormControl fullWidth className={className} margin="normal" variant="outlined">
            <RtlProvider>
                <TextField
                    multiline
                    rows={7}
                    {...field}
                    {...props}
                    fullWidth
                    inputProps={{ style: { direction: "rtl" } }}
                    label={label}
                    placeholder={placeholder}
                    variant="outlined"
                />
                {errorMessage?.message ? <FormHelperText error>{errorMessage?.message}</FormHelperText> : null}
            </RtlProvider>
        </FormControl>
    );
};

export default FormTextarea;
