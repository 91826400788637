import * as React from "react";
import { ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import { darkTheme, lightTheme } from "@/theme";
import { useThemeStore } from "@/context/theme";

const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
});

export default function RtlDemo({ children }: { children: React.ReactNode }) {
    const { theme } = useThemeStore();
    const themeProp = theme === "dark" ? darkTheme : lightTheme;

    themeProp.direction = "rtl";

    return (
        <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={themeProp}>
                <div dir="rtl">{children}</div>
            </ThemeProvider>
        </CacheProvider>
    );
}
