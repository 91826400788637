import { useState } from "react";
import { Paper, Box, Typography, Pagination } from "@mui/material";

import PublisherPageStyle from "./style";
import ShowPublishers from "./show";
import AddPubLishers from "./add";

import { usePublisherList } from "@/api/hooks/publisher";

// useInfiniteQuery of tanstack dose not support invalidation

export default function Publisher() {
    const [params, setParams] = useState({ skip: 0, take: 7, totalItems: 0, page: 1 });

    const { data, refetch } = usePublisherList({ skip: params.skip, take: params.take });

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setParams((prev) => ({
            ...prev,
            skip: value * 10,
        }));
    };

    return (
        <PublisherPageStyle>
            <Typography component="h1" sx={{ fontSize: "25px", fontWeight: "bold", margin: "0 0 1em 0" }}>
                Publishers
            </Typography>
            <Box className="center">
                <Paper className="paper">
                    <AddPubLishers onCreated={refetch} />
                    <ShowPublishers data={data?.data.items || []} onDelete={refetch} />
                </Paper>
                <br />
                <Pagination count={data?.data.pagination.totalPages} page={params.page} onChange={handleChange} />
            </Box>
        </PublisherPageStyle>
    );
}
